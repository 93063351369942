
export const environment = {
  production: false,
  apiUrl: 'https://api.dev.digitalreception.ch/v1',
  defaultAppLanguage: 'de',
  hasRemoteLogging: false,
  title: 'Kowerk Dietlikon',
  instanceKey: 'kowerk',
  templateKey: '.kowerk',
  instanceReception: {
    key: 'west',
    label: 'WEST ENTRANCE'
  },
  cardDispenserExtensionId: '',
  logoUrl: '/assets/img/logo/kowerk-logo-dark.png',
  carouselImages: [
    'assets/img/carousel/kowerk/slide1.png',
    'assets/img/carousel/kowerk/slide2.jpg',
    'assets/img/carousel/kowerk/slide3.jpg',
    'assets/img/carousel/kowerk/slide4.jpg',
    'assets/img/carousel/kowerk/slide5.jpg',
    'assets/img/carousel/kowerk/slide6.jpg',
    'assets/img/carousel/kowerk/slide7.jpg',
  ]
};

